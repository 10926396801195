import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router';
import reportWebVitals from './report-web-vitals';
import './assets/css/reset.less';
import './assets/css/common.less';

const APP = () => {
    return <Router />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<APP />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
